import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";
import { bookAppointment } from "./bookAppointment.api";
// import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
// import { SIGN_IN } from "graphql/mutations";
// import { client } from "graphql/client";

export const authApi = createApi({
  baseQuery: baseQueryWithReauth,
  // baseQuery: graphqlRequestBaseQuery({ client }),
  reducerPath: "authApi",
  tagTypes: ['USER', 'NOTIFICATIONS', 'DROPDOWN_NOTIFICATIONS'],
  endpoints: (build) => ({
    registration: build.mutation({
      query(body) {
        return {
          url: `/api/patient/register/`,
          method: "POST",
          body
        };
      },
    }),
    // login: build.mutation({
    //   query: ({ email, password }) => ({
    //     document: SIGN_IN,
    //     variables: { email, password },
    //   }),
    // }),
    login: build.mutation({
      query({ is_first_login, ...body }) {
        return {
          url: `/api/patient/login/?`,
          method: "POST",
          body,
          params: {
            ...(is_first_login ? { is_first_login } : {})
          }
        };
      },
    }),
    getUser: build.query({
      query() {
        return {
          url: '/api/patient/details/',
          method: "GET"
        };
      },
      onQueryStarted: async (_arg, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(bookAppointment.util.invalidateTags('Appointments'));
        } catch (err) {
          console.log("error... ", err);
        }
      },
      // transformResponse: (resp) => {
      //   debugger;
      //   const [result] = resp || [];
      //   const { user, ...rest } = result;
      //   return { ...user, ...rest };
      // },
      providesTags: ['USER'],
    }),
    resetPasswordEmail: build.mutation({
      query(body) {
        return {
          url: `/api/patient/forget_password/`,
          method: "POST",
          body
        };
      },
    }),
    resetPassword: build.mutation({
      query(body) {
        return {
          url: `/api/patient/password_reset/`,
          method: "POST",
          body
        };
      },
    }),
    changePassword: build.mutation({
      query(body) {
        return {
          url: `/api/patient/password-change/`,
          method: "POST",
          body
        };
      },
    }),
    logout: build.mutation({
      query(body) {
        return {
          url: `/api/patient/logout/`,
          method: "POST",
          body
        };
      },
      transformResponse: (resp) => resp,
    }),
    updateProfile: build.mutation({
      query({ body, id }) {
        return {
          url: `/api/personas/me/${id}/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: ['USER'],
    }),
    updateUser: build.mutation({
      query(body) {
        return {
          url: `/api/patient/update/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: ['USER', 'NOTIFICATIONS']
    }),
    updateAddress: build.mutation({
      query(body) {
        return {
          url: `/api/patient/address/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: ['USER']
    }),
    updateNotifications: build.mutation({
      query(body) {
        return {
          url: `/api/patient/notification/`,
          method: "PATCH",
          body
        };
      },
      invalidatesTags: ['USER']
    }),
    createAddress: build.mutation({
      query(body) {
        return {
          url: `/api/patient/address/`,
          method: "POST",
          body
        };
      },
      invalidatesTags: ['USER']
    }),
    sendOtp: build.mutation({
      query() {
        return {
          url: `/modules/two-factor-authentication/send/otp`,
          method: "POST",
          body: {
            method: 'phone_number'
          }
        };
      },
    }),
    verifyOtp: build.mutation({
      query(code) {
        return {
          url: `/modules/two-factor-authentication/verify/otp`,
          method: "POST",
          body: {
            method: 'phone_number',
            code,
          }
        };
      },
      invalidatesTags: ['USER']
    }),
    getNotifications: build.query({
      query(page) {
        return {
          url: `/api/notifications/?page=${page}&page_size=20`,
          method: "GET",
        };
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg }) => {
        return {
          ...newItems,
          results: parseInt(arg) > 1
            ? [...currentCache.results, ...newItems.results]
            : currentCache.results
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg
      },
      transformResponse: resp => ({
        ...resp,
        next: resp.next ? new URLSearchParams(resp?.next?.split('?')[1])?.get('page') : null,
      }),
      providesTags: ['NOTIFICATIONS'],
    }),
    getDropdownNotifications: build.query({
      query({ page = 1, pageSize = 5 }) {
        return {
          url: `/api/notifications/?page=${page}&page_size=${pageSize}`,
          method: "GET",
        };
      },
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName;
      // },
      // merge: (currentCache, newItems) => ({
      //   ...newItems,
      //   results: [...currentCache.results, ...newItems.results]
      // }),
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg
      // },
      // transformResponse: resp => ({
      //   ...resp,
      //   next: resp.next ? resp.next.split('?page=')[1] : null,
      // }),
      providesTags: ['DROPDOWN_NOTIFICATIONS'],
    }),
    selectPackage: build.mutation({
      query(body) {
        return {
          url: `/api/billing/packages/`,
          method: "POST",
          body
        };
      },
      invalidatesTags: ['USER']
    }),
    updateUserGroup: build.mutation({
      query(body) {
        return {
          url: `/api/patient/user_groups/`,
          method: "POST",
          body
        };
      },
      invalidatesTags: ['USER']
    }),
    reassignProvider: build.mutation({
      query(body) {
        return {
          url: `/api/consultation/providers/reassign/`,
          method: "POST",
          body
        };
      },
      invalidatesTags: ['USER']
    }),
    updateFcmToken: build.mutation({
      query(body) {
        return {
          url: `/api/patient/update_fcm_token/`,
          method: "PATCH",
          body,
        };
      },
    }),
    unreadNotificationsCount: build.query({
      query() {
        return {
          url: `/api/notifications/unread_count/`,
          method: "GET",
        };
      },
    }),
    updateAboutMe: build.mutation({
      query(body) {
        return {
          url: `/api/patient/about_me/`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: ['USER']
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordEmailMutation,
  useRegistrationMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useLogoutMutation,
  useUpdateProfileMutation,
  useUpdateUserMutation,
  useSendOtpMutation,
  useVerifyOtpMutation,
  useGetNotificationsQuery,
  useGetDropdownNotificationsQuery,
  useUpdateAddressMutation,
  useCreateAddressMutation,
  useUpdateNotificationsMutation,
  useSelectPackageMutation,
  useUpdateUserGroupMutation,
  useReassignProviderMutation,
  useUpdateFcmTokenMutation,
  useUnreadNotificationsCountQuery,
  useUpdateAboutMeMutation,
} = authApi;
