import { createApi } from "@reduxjs/toolkit/query/react";
// import moment from "moment-timezone";
// import moment from 'moment-timezone/moment-timezone';
import moment from 'moment';
import { baseQueryWithReauth } from "store/baseQuery";
import { supplementStore } from "./supplementStore.api";
import { authApi } from "./auth.api";
// import { date } from "yup";


export const bookAppointment = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "bookAppointment",
  tagTypes: ["Appointments", "Days", "Slots"],
  endpoints: (build) => ({
    getAppointmentTypes: build.query({
      query(id) {
        return {
          url: `/api/consultation/appointments/types/?provider_id=${id}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return response?.data?.appointmentTypes ?? [];
      },
    }),
    getAvailableDays: build.query({
      query({ providerId, appId, dateFromMonth, timezone }) {
        return {
          url: `/api/consultation/appointments/available_days/?provider_id=${providerId}&appt_type_id=${appId}&date_from_month=${dateFromMonth}&timezone=${timezone}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        return response?.data?.daysAvailableForRange.map((item) => item) ?? [];
      },
    }),
    getAvailableSlots: build.query({
      query({ providerId, appId, date, timezone }) {
        return {
          url: `/api/consultation/appointments/available_slots/?provider_id=${providerId}&appt_type_id=${appId}&start_date=${date}&timezone=${timezone}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (response) => {
        return response?.data?.availableSlotsForRange.map(({ date }) => date) ?? [];
      },
    }),
    scheduleAppointment: build.mutation({
      query(body) {
        return {
          url: `/api/consultation/appointments/book/`,
          method: "POST",
          body
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(supplementStore.util.invalidateTags(["Cart"]));
        dispatch(authApi.util.invalidateTags(["USER"]));

      },
      invalidatesTags: ["Appointments"],
    }),
    getAppointments: build.query({
      query({ userId, filter = '', pagination = 0, page = 0, order_by = 'DATE_DESC', is_active = 0, page_size = 5 }) {
        return {
          url: `/api/consultation/appointments/?user_id=${userId}&filter=${filter}&should_paginate=${pagination}&offset=${page * 5}&page_size=${page_size}&order_by=${order_by}&is_active=${is_active}`,
          method: "GET",
        };
      },
      providesTags: ["Appointments"],
      transformResponse: (response) => {
        return {
          data: {
            ...response?.data,
            appointments: response?.data?.appointments.map(({ provider, start, end, id, zoom_join_url, metadata, pm_status, minimum_advance_cancel_time, minimum_advance_reschedule_time, requested_payment }) => ({
              id,
              title: 'event',
              allDay: false,
              // start: moment(start).toDate(),
              // end: moment(end).toDate(),
              start: moment(start, "YYYY-MM-DD HH:mm:ss Z").toISOString(),
              end: moment(end, "YYYY-MM-DD HH:mm:ss Z").toISOString(),
              col: "#ee3559",
              providerName: `Dr ${provider?.name}`,
              providerAvatar: provider?.avatar_url,
              service: JSON.parse(metadata)?.service_name ?? "",
              eventId: JSON.parse(metadata)?.google_event_id ?? "",
              zoomUrl: zoom_join_url ?? "",
              status: pm_status,
              cancelAppointmentThresholdTime: parseFloat(minimum_advance_cancel_time / 60),
              rescheduleAppointmentThresholdTime: parseFloat(minimum_advance_reschedule_time / 60),
              paymentIntent: requested_payment?.notes,
              appointment_date: start,
              services_provided: requested_payment?.services_provided,
              price: requested_payment?.price,
            })) ?? [],
          }
        }
      },
    }),
    getAppointmentById: build.query({
      query(id) {
        return {
          url: `/api/consultation/appointments/${id}/`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return response?.data?.appointment ?? {}
      },
      keepUnusedDataFor: 0,
    }),
    reScheduleAppointment: build.mutation({
      query({ appointmentId, ...body }) {
        return {
          url: `/api/consultation/appointments/${appointmentId}/reschedule/`,
          method: "PATCH",
          body,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(authApi.util.invalidateTags(["USER"]));
      },
      invalidatesTags: ["Appointments"],
    }),
    cancelAppointment: build.mutation({
      query({ id, paymentIntent, ...rest }) {
        return {
          url: `/api/consultation/appointments/${id}/`,
          method: "DELETE",
          body: {
            payment_intent_id: paymentIntent,
            ...rest
          }
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(authApi.util.invalidateTags(["USER"]));
      },
      invalidatesTags: ["Appointments"],
    }),
    getAppointmentSettings: build.query({
      query(id) {
        return {
          url: `/api/consultation/appointments/type_settings/?provider_id=${id}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return {
          cancelAppointmentThresholdTime: parseFloat(response?.data?.appointmentSetting?.minimum_advance_cancel_time / 60),
          rescheduleAppointmentThresholdTime: parseFloat(response?.data?.appointmentSetting?.minimum_advance_reschedule_time / 60),
        }
      }
    }),
    createInvoice: build.mutation({
      query(body) {
        return {
          url: `/api/consultation/appointments/add_async_appointment_to_cart/`,
          method: "POST",
          body,
        };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(supplementStore.util.invalidateTags(["Cart"]));
      }
    }),
  }),
});

export const {
  useGetAppointmentTypesQuery,
  useGetAvailableDaysQuery,
  useGetAvailableSlotsQuery,
  useScheduleAppointmentMutation,
  useGetAppointmentsQuery,
  useGetAppointmentByIdQuery,
  useReScheduleAppointmentMutation,
  useCancelAppointmentMutation,
  useGetAppointmentSettingsQuery,
  useCreateInvoiceMutation,
} = bookAppointment;
