import { CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import DeleteLogo from 'assets/logos/DeleteLogo';
import PackageDefaultThumb from 'assets/logos/PackageDefaultThumb.webp'
// import PackageDefaultThumb from 'assets/logos/PacakgeDefaultThumb.svg'



const HealthiePackageSummary = ({ isPackageRemoveLoading, offering, handlePackageDelete, packageAmount }) => {
  const { name, offering_image_url } = offering || {};

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" alignItems="flex-start" gap={2}>
        <img
          height={99}
          width={94}
          style={{ borderRadius: '14px', backgroundColor: '#F1F2F4' }}
          // src="https://glossmeup.com/wp-content/uploads/2022/01/Rinklex30-min.png"
          src={offering_image_url ?? PackageDefaultThumb}
        />
        {/* <Stack direction="column" gap={1}> */}
        {/* <div> */}
        <Typography fontWeight={700} variant="body2" >{name}</Typography>
        {/* <Typography variant='caption' color="#666F82">{variant === 'Default Title' ? '' : variant}</Typography> */}
        {/* </div> */}
        {/* </Stack> */}
      </Stack>
      <Stack direction="column" justifyContent="space-between" alignItems="center" sx={{ overflow: 'hidden' }} flexShrink={0}>
        <Typography color="primary" variant='body2' fontWeight={500} >${parseFloat(packageAmount).toFixed(2)}</Typography>
        {isPackageRemoveLoading ?
          <CircularProgress sx={{ padding: '8px' }} size={15} thickness={1.5} />
          : <IconButton onClick={handlePackageDelete}>
            <DeleteLogo />
          </IconButton>
        }
      </Stack>
    </Stack>
  )
}


export default HealthiePackageSummary;