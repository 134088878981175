import { createApi, retry } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "store/baseQuery";

const staggeredBaseQuery = retry(baseQueryWithReauth, {
  maxRetries: 0,
})

export const paymentsApi = createApi({
  baseQuery: staggeredBaseQuery,
  reducerPath: "paymentsApi",
  tagTypes: ['PaymentMethods', "Invoices"],
  endpoints: (build) => ({
    getTiers: build.query({
      query({ providerId, userGroupId, menopausalStatus = 'PRE' }) {
        return {
          url: `/api/billing/packages/?provider_id=${providerId}&healthie_user_group_id=${userGroupId}&menopausal_status=${menopausalStatus}`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return response?.results;
      },
    }),
    getPaymentIntent: build.mutation({
      query(body) {
        return {
          url: `/api/billing/payments/payment_intent/`,
          method: "POST",
          body
        };
      },
      // extraOptions: { maxRetries: 3 }
    }),
    confirmPayment: build.mutation({
      queryFn: async ({ stripe, elements }) => {
        const resp = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          confirmParams: {
            return_url: `${process.env.REACT_APP_BASE_URL}order-confirmed`,
          },
          // confirmParams: {
          //   payment_method_data: {
          //     billing_details: {
          //       name,
          //     }
          //   }
          // }
        });
        return { data: resp };
      },
    }),
    getPaymentMethods: build.query({
      query() {
        return {
          url: `/api/billing/payment_methods/?limit=10&offset=0&type=card`,
          method: "GET",
        };
      },
      providesTags: ['PaymentMethods'],
    }),
    deletePaymentMethod: build.mutation({
      query(id) {
        return {
          url: `/api/billing/payment_methods/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ['PaymentMethods']
    }),
    setupIntent: build.query({
      query() {
        return {
          url: `/api/billing/payment_methods/setup_intent/`,
          method: "GET",
        };
      },
    }),
    addPaymentMethod: build.mutation({
      queryFn: async ({ stripe, elements }) => {
        try {
          const resp = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
              return_url: `${process.env.REACT_APP_BASE_URL}appointments`,
            },
          });

          // Check if the response contains an error
          if (resp.error) {
            // debugger;
            return { error: { status: 'PAYMENT_ERROR', data: resp } };
          }

          // If no error, return the successful response
          return { data: resp };
        } catch (error) {
          // Handle any unexpected errors that might occur during the request
          return { error: { status: 'UNEXPECTED_ERROR', data: error } };
        }
      },
      invalidatesTags: () => ["PaymentMethods"],
    }),
    makeDefaultMethod: build.mutation({
      query(id) {
        return {
          url: `/api/billing/payment_methods/${id}/`,
          method: "PUT",
        };
      },
      invalidatesTags: ['PaymentMethods']
    }),
    getInvoices: build.query({
      query({ offset = '' }) {
        return {
          url: `/api/billing/payments/?limit=30&offset=${offset}`,
          method: "GET",
        };
      },
      transformResponse: (response) => ({
        invoices: response,
        cursor: response?.length > 0 ? response.at(-1)?.id : ''
      }),
      providesTags: ['Invoices'],
      keepUnusedDataFor: 0,
    }),
    downloadInvoice: build.mutation({
      query(body) {
        return {
          url: `/api/billing/payments/download_receipt/`,
          method: "POST",
          body,
          responseHandler: (response) => response?.blob?.()
        };
      },
    }),
  }),
});

export const {
  useGetTiersQuery,
  useConfirmPaymentMutation,
  useGetPaymentIntentMutation,
  useGetPaymentMethodsQuery,
  useDeletePaymentMethodMutation,
  useLazySetupIntentQuery,
  useAddPaymentMethodMutation,
  useMakeDefaultMethodMutation,
  useGetInvoicesQuery,
  useDownloadInvoiceMutation,
} = paymentsApi;
