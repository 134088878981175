import { Outlet, Navigate, useSearchParams } from "react-router-dom";
import Layout from "components/Layout";
import useAuth from "shared/hooks/useAuth";
import Loader from "components/Loader";
import { ApolloProvider } from "@apollo/client";
import useApolloClient from "graphql/useApolloClient";
import { saveLastLocation } from "utils/helperFunctions";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
  const selfLogout = useSelector((state) => state.user.selfLogout);
  const { isLoading, authenticated, healthieToken = '', userId } = useAuth();
  const { client } = useApolloClient({ healthieToken, userId });
  const [param] = useSearchParams();
  const defaultEmail = param.get("email") ?? "";

  if (isLoading) {
    return <Loader pageLoader />
  }

  if (!client && authenticated) {
    return <Loader pageLoader />
  }

  if (
    authenticated
  ) {
    return (
      <ApolloProvider client={client}>
        <Layout>
          <Outlet />
        </Layout>
      </ApolloProvider>
    );
  }

  if (!isLoading && !authenticated && !selfLogout) {
    saveLastLocation();
  }

  return <Navigate to={!defaultEmail ? "/login" : `/login?email=${defaultEmail}`} />;
};

export default ProtectedRoute;
